import Bluebird from 'bluebird'
import 'handjs'
import * as Sentry from '@sentry/browser'

import {
  ArcRotateCamera,
  Camera,
  Color4,
  CubeTexture,
  Engine,
  Scene,
  SceneLoader,
  Vector3,
} from '@babylonjs/core'

import * as _ from '@technically/lodash'

import { CAMERA_DEF_DICT } from '../common/sheets'
import { Settings } from './RenderData'
import {
  initializeTextures,
  updateTextures,
  disposeTextures,
  areTexturesReady,
} from './TextureState'
import updateProjectionMatrix from '~p/client/render3d/updateProjectionMatrix'

// -----------------
// UTILS
// -----------------
// Assuming that less pixels means that it's a low-end device.
const getTextureSizeFromPixels = (pixels) => {
  if (pixels > 2000 * 2000) {
    return 4096
  }
  // approximating the previous value of 500000
  if (pixels > 700 * 700) {
    return 2048
  }
  return 1024
}

const getTextureSize = (window, capabilities) => {
  const pixels =
    window.innerWidth * window.innerHeight * (window.devicePixelRatio || 1)
  const textureSizeFromPixels = getTextureSizeFromPixels(pixels)
  return Math.min(textureSizeFromPixels, capabilities.maxTextureSize)
}

// -----------------
// IMPLEMENTATION
// -----------------
const setDirty = (state) => {
  state.isDirty = true
}

const isRenderReady = (state) => {
  const textures =
    state.model && state.model.status === 'loaded' && state.model.textures
  return state.scene.isReady() && (!textures || areTexturesReady(textures))
}

export const createRenderState = (seed, data) => {
  const engine = new Engine(
    seed.canvas,
    true,
    {
      limitModelRatio: 3,
      doNotHandleTouchAction: true,
      alpha: true,
    },
    true,
  )
  engine.enableOfflineSupport = false
  engine.doNotHandleContextLost = true

  const capabilities = engine.getCaps()
  const { innerWidth, innerHeight, devicePixelRatio } = window

  Sentry.addBreadcrumb({
    customizer: 'rawlings-helmets',
    category: 'webgl',
    level: 'debug',
    message: 'engine created',
    data: {
      ...capabilities,
      innerWidth,
      innerHeight,
      devicePixelRatio,
    },
  })

  seed.canvas.removeEventListener('webglcontextlost', () => {
    Sentry.addBreadcrumb({
      customizer: 'rawlings-helmets',
      category: 'webgl',
      level: 'debug',
      message: 'context lost',
      data: {
        scaleFactor: engine.getHardwareScalingLevel(),
        canvasWidth: seed.canvas.width,
        canvasHeight: seed.canvas.height,
      },
    })
    Sentry.captureException(new Error('WebGL context lost'))
    seed.onCrash()
  })

  seed.canvas.removeEventListener('webglcontextrestored', () => {
    Sentry.addBreadcrumb({
      customizer: 'rawlings-helmets',
      category: 'webgl',
      level: 'debug',
      message: 'context restored',
    })
  })

  Settings.MAX_TEXTURE_SIZE = getTextureSize(window, capabilities)

  const scene = new Scene(engine)
  scene.clearColor = new Color4(0, 0, 0, 0)

  const environmentTexture = CubeTexture.CreateFromPrefilteredData(
    data.environmentTexture,
    scene,
  )

  const cameraDef = data.cameraDef
  const [x, y, z] = cameraDef.target

  const camera = new ArcRotateCamera(
    'camera',
    cameraDef.alpha,
    cameraDef.beta,
    cameraDef.radius,
    new Vector3(x, y, z),
    scene,
    true,
  )
  camera.upperRadiusLimit = cameraDef.radius
  camera.lowerRadiusLimit = 0.33 * cameraDef.radius
  camera.fov = cameraDef.fov

  camera.panningSensibility = 0
  camera.minZ = 0.001
  camera.inertia = 0
  camera.panningInertia = 0
  camera.wheelPrecision = 100
  camera.angularSensibilityX = 250
  camera.angularSensibilityY = 750
  camera.lowerBetaLimit = Math.PI / 2 - (Math.PI / 2) * 1
  camera.upperBetaLimit = Math.PI / 2 + (Math.PI / 2) * 1
  camera.attachControl(seed.canvas)

  const state = {
    ...seed,
    engine,
    scene,
    environmentTexture,
    camera,

    model: undefined,

    isLoading: true,
    isDirty: true,

    prevData: undefined,
  }

  updateProjectionMatrix(camera, engine, cameraDef)

  const onLoadUpdateOriginal = state.onLoadUpdate
  state.onLoadUpdate = () => {
    setDirty(state)
    onLoadUpdateOriginal()
  }

  // Not to allocate on every frame
  let hasInputChanges = false
  const renderLoop = () => {
    hasInputChanges = _.some(
      _.pick(camera, [
        'inertialAlphaOffset',
        'inertialBetaOffset',
        'inertialRadiusOffset',
      ]),
    )

    if (hasInputChanges) {
      setDirty(state)
    }

    if (state.isLoading) {
      if (isRenderReady(state)) {
        state.isLoading = false
        setDirty(state)
        state.onLoadUpdate()
      }
    }

    if (!state.isDirty) {
      return
    }

    state.isDirty = false
    if (!state.isLoading) {
      scene.render(true, true)
    }
  }
  engine.runRenderLoop(renderLoop)

  return state
}

const resizeScene = _.debounce((state, data) => {
  Settings.MAX_TEXTURE_SIZE = getTextureSize(window, state.engine.getCaps())

  const camera = state.camera
  const { width, height, cameraDef } = data
  camera.fovMode =
    width > height ?
      Camera.FOVMODE_VERTICAL_FIXED
    : Camera.FOVMODE_HORIZONTAL_FIXED

  const scaleFactor = state.engine.getHardwareScalingLevel()
  state.engine.setSize(
    Math.round(width / scaleFactor),
    Math.round(height / scaleFactor),
  )

  updateProjectionMatrix(state.camera, state.engine, cameraDef)

  // TODO applying the hacky fix to invalidate camera projection
  const oldAlpha = state.camera.alpha
  state.camera.alpha += 0.001
  state.scene.render()
  state.camera.alpha = oldAlpha
  setDirty(state)
}, 100)

const updateCamera = (state, data) => {
  const { width, height, cameraDef } = data

  // camera.alpha = cameraDef.alpha
  // camera.beta = cameraDef.beta
  // camera.radius = cameraDef.radius
  // camera.upperRadiusLimit = Math.max(2, cameraDef.radius)
  // camera.lowerRadiusLimit = Math.min(0.5, cameraDef.radius)
  // camera.fov = cameraDef.fov

  if (
    !state.prevData ||
    (state.prevData.width === width &&
      state.prevData.height === height &&
      JSON.stringify(state.prevData.cameraDef.translateProjection) ===
        JSON.stringify(cameraDef.translateProjection))
  ) {
    return
  }

  resizeScene(state, data)
}

const removeModel = (state, modelState) => {
  if (modelState.status === 'loaded') {
    modelState.assetContainer.removeAllFromScene()
    modelState.assetContainer.dispose()
    disposeTextures(modelState.textures)
  } else {
    modelState.assetLoader.isCancelled = true
  }
}

const loadModel = (url, scene, onLoadUpdate) => {
  const promise = SceneLoader.LoadAssetContainerAsync(url, undefined, scene)
  const assetLoader = {
    isCancelled: false,
    promise,
  }

  promise
    .then((assetContainer) => {
      if (assetLoader.isCancelled) {
        console.log(`Loaded device too late, disposing - url: ${url}`)
        assetContainer.dispose()
        return null
      }

      console.log(`Loaded device - url: ${url}`)
      assetLoader.assetContainer = assetContainer
      onLoadUpdate()
      return assetContainer
    })
    .catch((reason) => {
      throw new Error(`Can not load device - reason: ${reason}. url: ${url}`)
    })

  return {
    assetLoader,
    status: 'loading',
  }
}

const isMeshVisible = (meshName, meshVisibilityData) => {
  if (meshName in meshVisibilityData.faceguards) {
    return meshVisibilityData.faceguards[meshName]
  }
  if (_.startsWith(meshName, 'ADJEXT-Flap')) {
    return (
      meshVisibilityData.faceguards['ADJEXT-Flap'] &&
      (meshVisibilityData.flapSide === 'right' ?
        _.startsWith(meshName, 'ADJEXT-Flap_right ')
      : _.startsWith(meshName, 'ADJEXT-Flap_left'))
    )
  }
  if (_.startsWith(meshName, 'EXT-Flap')) {
    return (
      meshVisibilityData.faceguards['EXT-Flap'] &&
      _.endsWith(meshName, meshVisibilityData.flapSide || 'none')
    )
  }
  if (_.endsWith(meshName, 'screws') || _.endsWith(meshName, 'screwsADJEXT')) {
    return meshVisibilityData.screws
  }
  if (meshName === 'inserts' || meshName === 'carbonWeave') {
    return meshVisibilityData.carbonInserts
  }
  if (meshName === 'carbonWeaveMask') {
    return meshVisibilityData.carbonWeaveMask
  }
  if (_.startsWith(meshName, 'frontPadding')) {
    return (
      meshName === `frontPadding${meshVisibilityData.frontPaddingSuffix || ''}`
    )
  }
  if (_.startsWith(meshName, 'mlbLogo')) {
    return meshName === 'mlbLogo' || meshName === 'mlbLogoDefault'
  }

  if (_.startsWith(meshName, 'techLogo_')) {
    const meshId = meshName.replace('techLogo_', '')
    return !!meshVisibilityData.techLogoVisibility[meshId]
  }
  return true
}

/**
 * @param {object} data
 * @param {string} filename
 * @returns {void}
 */
const saveJsonToFile = (data, filename) => {
  const dataUrl = URL.createObjectURL(
    new Blob([data], { type: 'application/json' }),
  )

  const link = document.createElement('a')
  link.href = dataUrl
  link.download = filename
  link.click()

  URL.revokeObjectURL(dataUrl)
}

const updateModel = (state, data, modelState, url, prevUrl) => {
  if (!modelState) {
    if (!url) {
      // EMPTY
      return undefined
    }

    // ADD
    return loadModel(url, state.scene, state.onLoadUpdate)
  }

  if (!url) {
    // REMOVE
    return removeModel(state, modelState)
  }

  if (url !== prevUrl) {
    // CHANGE
    removeModel(state, modelState)
    return loadModel(url, state.scene, state.onLoadUpdate)
  }

  const setMeshVisibilities = (assetContainer) => {
    _.forEach(assetContainer.meshes, (mesh) => {
      const isVisible = isMeshVisible(mesh.id, data.meshVisibilityData)

      if (mesh.isVisible !== isVisible) {
        mesh.isVisible = isVisible
        setDirty(state)
      }
    })
  }

  const assetContainer =
    modelState.status === 'loading' && modelState.assetLoader.assetContainer

  if (assetContainer) {
    const getVisibleMeshes = () =>
      assetContainer.meshes.filter((mesh) => mesh.isVisible)
    const getVisibleMeshNames = () =>
      getVisibleMeshes().map((mesh) => mesh.name)

    // FINISH LOADING
    console.log('FINISH LOADING', state)

    const visibleMeshesToNotExport = ['assetContainerRootMesh', '__root__']

    window.exportMaterials = (filename = 'helmet-materials.json') => {
      const namesOfMeshesToExport = getVisibleMeshNames().filter(
        (name) => !visibleMeshesToNotExport.includes(name),
      )

      const meshesToExport = assetContainer.meshes.filter((mesh) =>
        namesOfMeshesToExport.includes(mesh.name),
      )

      const meshMaterialData = meshesToExport
        .map((mesh) => {
          const { material } = mesh
          if (!material) return [mesh.name, null]

          return [
            mesh.name,
            // Export all relevant material properties so they could be reconstructed as precisely as possible
            {
              albedoTexture: material.albedoTexture?.serialize(),
              albedoColor: material.albedoColor?.asArray(),
              metallic: material.metallic,
              roughness: material.roughness,
              metallicTexture: material.metallicTexture?.serialize(),
              bumpTexture: material.bumpTexture?.serialize(),
              emissiveTexture: material.emissiveTexture?.serialize(),
              emissiveColor: material.emissiveColor?.asArray(),
              emissiveIntensity: material.emissiveIntensity,
              ambientTexture: material.ambientTexture?.serialize(),
              ambientTextureStrength: material.ambientTextureStrength,
              alpha: material.alpha,
              transparencyMode: material.transparencyMode,
              useAlphaFromAlbedoTexture: material.useAlphaFromAlbedoTexture,
              environmentBRDFTexture:
                material.environmentBRDFTexture?.serialize(),
              environmentIntensity: material.environmentIntensity,
              backFaceCulling: material.backFaceCulling,
              wireframe: material.wireframe,
              forceIrradianceInFragment: material.forceIrradianceInFragment,
              invertNormalMapX: material.invertNormalMapX,
              twoSidedLighting: material.twoSidedLighting,
              useMetallnessFromMetallicTextureBlue:
                material.useMetallnessFromMetallicTextureBlue,
              useRadianceOverAlpha: material.useRadianceOverAlpha,
              useRoughnessFromMetallicTextureAlpha:
                material.useRoughnessFromMetallicTextureAlpha,
              useRoughnessFromMetallicTextureGreen:
                material.useRoughnessFromMetallicTextureGreen,
              useSpecularOverAlpha: material.useSpecularOverAlpha,
            },
          ]
        })
        .filter(([_name, material]) => material)

      const dataForExport = JSON.stringify(Object.fromEntries(meshMaterialData))

      saveJsonToFile(dataForExport, filename)
    }

    _.each(assetContainer.materials, (material) => {
      material.reflectionTexture = state.environmentTexture
    })
    const rootContainer = assetContainer.createRootMesh()
    assetContainer.addAllToScene()
    setMeshVisibilities(assetContainer)
    const textures = initializeTextures(assetContainer)
    setDirty(state)
    state.scene.clearCachedVertexData()

    return {
      assetContainer,
      rootContainer,
      textures,
      status: 'loaded',
    }
  }

  if (modelState.status === 'loaded') {
    setMeshVisibilities(modelState.assetContainer)
  }

  return modelState
}

export const updateRenderState = (state, data) => {
  const prevData = state.prevData
  updateCamera(state, data)

  const url = data.modelGlb
  const prevUrl = prevData && prevData.modelGlb
  state.model = updateModel(state, data, state.model, url, prevUrl)
  if (state.model && state.model.status === 'loaded') {
    updateTextures(state.model.textures, data)
  }

  state.prevData = data

  if (!isRenderReady(state)) {
    state.isLoading = true
  }
}

export const disposeRenderState = (state) => {
  console.log('RenderState dispose')
  if (state.model) {
    state.model = removeModel(state, state.model)
  }

  // TODO fix in BJS scene.dispose
  while (state.scene.transformNodes.length) {
    const node = state.scene.transformNodes[0]
    state.scene.removeTransformNode(node)
    node.dispose()
    state.scene.transformNodes.shift()
  }

  state.environmentTexture.dispose()
  state.camera.dispose()
  state.scene.dispose()
  state.engine.dispose()
}

const getRenderSettings = (canvas, camera) => ({
  width: canvas.width,
  height: canvas.height,
  target: [camera.target.x, camera.target.y, camera.target.z],
  alpha: camera.alpha,
  beta: camera.beta,
  radius: camera.radius,
  fov: camera.fov,
})

const applyRenderSettings = (engine, camera, cameraDef) => {
  engine.setSize(cameraDef.width, cameraDef.height)
  camera.fovMode =
    cameraDef.width > cameraDef.height ?
      Camera.FOVMODE_VERTICAL_FIXED
    : Camera.FOVMODE_HORIZONTAL_FIXED

  const [x, y, z] = cameraDef.target

  camera.alpha = cameraDef.alpha
  camera.beta = cameraDef.beta
  camera.target = new Vector3(x, y, z)
  camera.upperRadiusLimit = cameraDef.radius
  camera.lowerRadiusLimit = 0.33 * cameraDef.radius
  camera.radius = cameraDef.radius
  camera.fov = cameraDef.fov
}

const getCanvasBlob = (canvas) =>
  new Promise((resolve, reject) => {
    if (canvas.width === 0 || canvas.height === 0) {
      reject(new Error('canvas has no dimensions'))
      return
    }

    canvas.toBlob((blob) => {
      resolve(blob)
    })
  })

export const generatePreviews = async (state, viewNames) => {
  const { canvas, engine, scene, camera } = state
  const cameraDefBackup = getRenderSettings(canvas, camera)

  const blobs = await Bluebird.mapSeries(viewNames, async (viewName) => {
    const cameraDef = CAMERA_DEF_DICT[viewName]
    if (!cameraDef) {
      throw new Error(`cameraDef not found for viewName: ${viewName}`)
    }
    applyRenderSettings(engine, camera, cameraDef)
    scene.render()

    const blob = await getCanvasBlob(canvas)
    return blob
  })
  applyRenderSettings(engine, camera, cameraDefBackup)

  return _.pickBy(_.zipObject(viewNames, blobs), _.identity)
}
